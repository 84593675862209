@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
.font-condensed {
    font-stretch: condensed;
}
}

.y-scrollable-tag::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width */
}

.y-scrollable-tag::-webkit-scrollbar-thumb {
    background: #202020; /* Set scrollbar thumb color */
    border-radius: 2px; /* Apply rounded corners */
}

.y-scrollable-tag::-webkit-scrollbar-track {
    background: transparent; /* Set scrollbar track color */
}

.x-scrollable-tag::-webkit-scrollbar {
    width: 6px; /* Adjust scrollbar width */
    height: 6px;
}

.x-scrollable-tag::-webkit-scrollbar-thumb {
    background: #c0c0c0; /* Set scrollbar thumb color */
    border-radius: 2px; /* Apply rounded corners */
}

.user-profile-section {
    border-radius: 0.25rem;
    gap: 6px;
    transition: .5s all ease;
    cursor: pointer;
}

.dark [type=checkbox]:checked, .dark [type=radio]:checked, [type=checkbox]:checked, [type=radio]:checked {
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  border-color: transparent;
  background-size: 1em 1em;
}

.menu-section {
    border: #fff solid 2px;
    border-radius: 8px;
    padding: 10px 14px;
    gap: 6px;
    transition: .5s all ease;
    cursor: pointer;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

select:not([size]) {
  padding-right: 0.5rem;
}

.card-token {
  background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%);
  color: #fff;
}

@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
      max-width: 1160px;
  }
}

.language-menu-section {
    border-radius: 0.25rem;
    transition: .5s all ease;
    cursor: pointer;
}

.flag-select__btn {
  padding: 0px 4px;
  color: #e5e7eb;
  border: #e5e7eb solid 1px;
  border-radius: 24px;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag-select {
  position: relative;
  display: inline-block;
  vertical-align: inherit;
  padding-bottom: 0px;
  text-align: left;
}

.flag-select__option {
  cursor: pointer;
  padding: 0 2px;
  white-space: nowrap;
}

.flag-select__options {
  position: absolute;
  top: -180px;
  z-index: 999999;
  border: 1px solid #bdbbbb;
  border-radius: 3px;
  background: #fff;
  margin-top: 8px;
  padding: 8px 0;
  max-height: 160px;
  overflow: auto;
}

.flag-select__btn:after {
border-top: 5px solid #e5e7eb;
border-bottom: 0;
}

.notification-icon {
    padding: 0.25rem;
    transition: .5s all ease;
    cursor: pointer;
}

.notification-icon:hover .svg-icon path{
    fill:  #0060D9; 
}

.profile-user-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    text-align: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}
.history-content {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 25px;
    display: flex;
}
.history-item {
    padding: 10px;
    border-bottom: 1px solid #2F2D3B;
    gap: 10px;
}
.history-item-icon {
    width: 36px;
    height: 36px;
    border-radius: 4px;
}
.history-item-text {
    font-weight: 400;
    color: white;
    font-size: 14px;
    line-height: 17.5px;
}
.navli {
    transition: .1s all ease;
}
.parent-navlink {
    transition: .1s all ease;
}
.parent-navlink:hover .nav-border {
    background-color:  #2c80ff; 
}
.parent-navlink-active .nav-border {
    background-color:  #2c80ff; 
}
.parent-navlink:hover .svg-icon path {
    fill:  #8793a1; 
}
.parent-navlink-active .svg-icon path {
    fill:  #8793a1; 
}
.banner-item {
    transition: .4s all ease;
}  
.banner-item:hover {
    translate: -0.02rem;
    filter: drop-shadow(0px 0px 15px rgba(6, 116, 252, 0.2));
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 9px;
}
.banner-img:hover .extend-play {
    opacity: 100;
    filter: drop-shadow(0px 0px 10px rgba(6, 116, 252, 0.65));
    transition: .2s all ease-out;
}
.banner-img:hover .banner-play{
    transition: .2s all ease-out;
    background-color: rgb(172, 6, 6);
}
.fill-btn-green {
    transition: .5s all ease;
}
.fill-btn-green:hover {
    box-shadow: 0px 5px 10px 0px rgba(49, 196, 141, 0.45) !important;
    background-color: rgb(49, 196, 141);
}
.fill-btn-purple {
    transition: .5s all ease;
}
.fill-btn-purple:hover {
    box-shadow: 0px 5px 10px 0px rgba(85, 33, 181, 0.45) !important;
    background-color: rgb(85, 33, 181);
}
.fill-btn-red:hover {
    box-shadow: 0px 5px 10px 0px rgba(253, 54, 59, 0.45) !important;
    background-color: rgb(253, 54, 59);
}
.fill-btn-primary {
    transition: .5s all ease;
}
.fill-btn-primary:hover {
    box-shadow: 0px 5px 10px 0px rgba(6, 116, 252, 0.45) !important;
    background-color: rgb(6, 96, 217);
}
.fill-btn-fourth {
    transition: .5s all ease;
}
.fill-btn-fourth:hover {
    box-shadow: 0px 5px 10px 0px rgba(6, 116, 252, 0.45) !important;
    background-color: rgb(6, 96, 217);
}
.fill-btn-third {
    transition: .5s all ease;
    background-color: rgb(4, 40, 87);
}
.fill-btn-third:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 16, 36, 0.45) !important;
    background-color: rgb(4, 40, 87);
}
.fill-btn-second {
    transition: .5s all ease;
}
.fill-btn-second:hover {
    box-shadow: 0px 5px 10px 0px rgba(253, 54, 59, 0.45) !important;
    background-color: rgb(253, 54, 59);
}
.fill-btn-continue:hover {
  transition: .5s all ease;
  background-color: #086bff;
}
.outline-btn {
    transition: .5s all ease;
}
.outline-btn:hover{
    transition: .5s all ease;
    background-color: rgba(42, 32, 32, 0.85);
    color:rgb(253, 54, 59, 0.9);
}
.outline-btn {
  transition: .5s all ease;
}

input[type="checkbox"].form-checkbox:checked + svg {
    fill: #0060D9; /* Change the color of the check mark when checked */
}
.slideshow {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 300px; /* Adjust height as needed */
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.slide.active {
    opacity: 1;
}

/* .group:hover .svg-icon path{
    transition: .5s all ease;
    fill: #0060D9;
}

.svg-icon:hover path{
    transition: .5s all ease;
    fill: #0060D9;
}

.menu-item:hover .svg-icon path {
    transition: .5s all ease;
    fill: #0060D9;
} */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    border-radius: 8px;
    max-height: 90%; 
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
    position: relative; /* Required for positioning close button or other elements inside */
  }

.line-clamp-ellipsis-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* =========================
Custom Input range 
=============================*/

.progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  /* time */
  .time {
    color: #fff;
    font-size: 13px;
    line-height: 46px;
  }
  
  .time.current {
    color: #3D83F7;
  }

/* Input range - chrome and safari */

input[type="range"] {
    --range-progress: 0;
    
    -webkit-appearance: none;
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* Input range - firefox */
  input[type="range"]::-moz-range-track {
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* played progress length - Chrome & safari*/
  input[type="range"]::before {
    content: '';
    height: 3px;
    background: #0060D9;
    width: var(--range-progress);
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* played progress length - firefox */
  input[type="range"]::-moz-range-progress {
    background: #0060D9;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    height: 3px;
  }
  
  /* slider thumb - chrome and safari */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: #0060D9;
    cursor: pointer;
    visibility: hidden;
    position: relative;
  }
  
  /* dragging thumb - chrome and safari */
  input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
    visibility: visible;
  }

  input[type="range"]:hover::-webkit-slider-thumb {
    transform: scale(1.2);
    visibility: visible;
  }
  
  /* slider thumb - firefox */
  input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #0060D9;
    cursor: pointer;
    border: transparent;
    position: relative;
  }
  /* dragging thumb - firefox */
  input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }

  .banner-background {
    background: rgba(231, 229, 210, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    font-family: "Geogrotesque Wide", sans-serif;
  }
  
  @media only screen and (max-width: 500px) {
    .banner-background {
      margin: 0px 4px;
    }
  }
  
/* Override the default slick dots style */
.slick-dots.custom-dots {
    bottom: -30px; /* Adjust the position as needed */
  }
  
  .slick-dots.custom-dots li {
    margin: 0 4px; /* Adjust the spacing between dots */
  }
  
  .slick-dots.custom-dots li button:before {
    font-size: 12px; /* Adjust the dot size */
    color: #ccc; /* Default dot color */
  }
  
  .slick-dots.custom-dots li.slick-active button:before {
    color: #FFFFFFEE; /* Color for active dot */
  }
  
  /* Style for custom dot content */
  .custom-dot {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000088;
    color: transparent;
    border-radius: 50%;
    font-size: 12px;
    transition: background-color 0.3s ease;
  }
  
  .custom-dots li.slick-active .custom-dot {
    background-color: #FFFFFFEE;
  }

  .App {
    font-family: sans-serif;
  }
    /* Customize the dropdown itself */
  .custom-dropdown .p-dropdown {
    border-color: #d5d6d7; /* Change border color */
    border-radius: 4px; /* Round the corners */
    background-color: #FEFEFE;
    border-width: 1px;
  }

  .custom-dropdown {
    background-color: #FEFEFE;
    color: #FEFEFE;
  }
  
  /* Customize the dropdown filter input */
  .p-dropdown-panel {
    background-color: #FEFEFE;
    --tw-text-opacity: 1;
    border-radius: 8px;
    width: 14rem;
    color: #4c4f52;
    position: absolute;
    top: 10px;
    margin-top: 4px;
  }

  .p-dropdown-header {
    background-color: #FEFEFE;
    --tw-text-opacity: 1;
    border-radius: 8px;
    color: #4c4f52;
  }

  .p-dropdown-label {
    --tw-text-opacity: 1;
    color: #4c4f52;
  }

  .p-dropdown-item {
    --tw-text-opacity: 1;
    color: #4c4f52;
  }

  .p-dropdown-item :hover {
    --tw-text-opacity: 1;
    color: #4c4f52;
  }

  /* Customize the dropdown filter input */
  .custom-dropdown .p-dropdown-filter {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #4c4f52;
    margin-bottom: 10px;
    color: #4c4f52;
  }
  
  .p-dropdown-filter-container {
    background-color: #FEFEFE;
  }

  .p-dropdown-filter {
    background-color: #FEFEFE;
    border-radius: 6px;
  }

  .p-dropdown-item {
    color: #4c4f52;
    background-color: #FEFEFE;
    border-radius: 0px;
  }

  .p-focus {
    background-color: #bebebe;
  }

  .pl-8{padding-left:2rem}

  .desktop-nav .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }
  
  .desktop-nav .menu-items {
    position: relative;
    font-size: 14px;
  }
  
  .desktop-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }
  
  .desktop-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  .desktop-nav button span {
    margin-left: 3px;
  }
  
  .desktop-nav .menu-items > a,
  .desktop-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .desktop-nav .menu-items a:hover,
  .desktop-nav .menu-items button:hover {
    background-color: #f2f2f2;
  }
  
  .desktop-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    top: 56px;
    left: 100%;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    transition: all .3s ease;
    border-radius: 1px;
    display: none;
  }

  .dropdown.show {
    display: block;
  }
  
  .navli:hover .dropdown {
    display: block;
  }
  
  .stage-card:hover {
    box-shadow: 0 5px 15px rgba(24, 30, 43, 0.1)
  }

  .payment-label {
    position: absolute;
    color: #fff;
    background: #bc69fb;
    font-size: 11px;
    line-height: 15px;
    padding: 30px 22px 7px 20px;
    height: 51px;
    width: 100px;
    transform: rotate(-45deg);
    left: -34px;
    top: -9px;
    text-transform: uppercase;
    text-align: center;
  }

  .data-details-list {
    border-radius: 4px;
    border: 1px solid #d2dde9;
}

.data-details {
  border-radius: 4px;
  padding: 18px 20px;
  border: 1px solid #d2dde9
}

.data-details > div {
  flex-grow: 1;
  margin-bottom: 18px
}

.data-details > div:last-child {
  margin-bottom: 0
}

.data-details-title {
  font-size: 14px;
  font-weight: 600;
  color: #758698;
  line-height: 20px;
  display: block
}

.data-details-info {
  font-size: 14px;
  font-weight: 400;
  color: #495463;
  line-height: 20px;
  display: block;
  margin-top: 6px
}

.data-details-info.large {
  font-size: 20px
}

.data-details-list {
  border-radius: 4px;
  border: 1px solid #d2dde9
}

.data-details-list li {
  display: block
}

.data-details-list li:last-child .data-details-des {
  border-bottom: none
}

.data-details-head {
  font-size: 13px;
  font-weight: 500;
  color: #758698;
  line-height: 20px;
  padding: 15px 20px 2px;
  width: 100%
}

.data-details-alt {
  border-radius: 4px;
  border: 1px solid #d2dde9;
  font-weight: 400;
}

.data-details-alt li {
  line-height: 1.35;
  padding: 15px 20px;
  border-bottom: 1px solid #d2dde9;
}

.data-details-des {
  font-size: 14px;
  color: #495463;
  font-weight: 400;
  line-height: 20px;
  padding: 2px 20px 15px;
  flex-grow: 1;
  border-bottom: 1px solid #d2dde9;
  display: flex;
  justify-content: space-between
}

.data-details-des .ti:not([data-toggle="tooltip"]),
.data-details-des [class*=fa]:not([data-toggle="tooltip"]) {
  color: #2c80ff
}

.data-details-des span:last-child:not(:first-child) {
  font-size: 12px;
  color: #758698
}

.data-details-des small {
  color: #758698
}

.data-details-des span,
.data-details-des strong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.data-details-docs {
  border-top: 1px solid #d2dde9;
  margin-top: 12px
}

.data-details-docs-title {
  color: #495463;
  display: block;
  padding-bottom: 6px;
  font-weight: 400
}

.data-details-docs > li {
  flex-grow: 1;
  border-bottom: 1px solid #d2dde9;
  padding: 20px
}

.data-details-docs > li:last-child {
  border-bottom: none
}

.data-details-alt {
  border-radius: 4px;
  border: 1px solid #d2dde9;
  font-weight: 400
}

.data-details-alt li {
  line-height: 1.35;
  padding: 15px 20px;
  border-bottom: 1px solid #d2dde9
}

.data-details-alt li:last-child {
  border-bottom: none
}

.data-details-alt li div {
  padding: 3px 0
}

.data-details-date {
  display: block;
  padding-bottom: 4px
}

@media (min-width: 576px) {
  .data-details-list > li {
      display: flex;
      align-items: center
  }

  .data-details-docs {
      width: calc(100% - 190px);
      border-top: none;
      margin-top: 0
  }

  .data-details-docs > li {
      border-left: 1px solid #d2dde9
  }

  .data-details-head {
      width: 190px;
      padding: 14px 20px
  }

  .data-details-des {
      border-top: none;
      border-left: 1px solid #d2dde9;
      width: calc(100% - 190px);
      padding: 14px 20px
  }

  .data-col .text-wrap {
      width: 140px
  }

  .data-col-wd-sm {
      width: 140px
  }

  .data-col-wd-md {
      width: 180px
  }

  .data-col-wd-lg {
      width: 220px
  }
}

@media (min-width: 768px) {
  .data-item .lead {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.02em
  }

  .data-item .sub {
      font-size: 12px;
      letter-spacing: 0.02em;
      padding-top: 7px
  }

  .data-item .sub-s2 {
      font-size: 13px;
      color: #495463
  }

  .data-details-info {
      font-size: 13px
  }

  .data-details > div {
      margin-bottom: 0
  }

  .data-details-head {
      font-size: 14px
  }

  .data-details-docs > li {
      width: 100%
  }

  .data-col .text-wrap {
      width: 150px
  }

  .data-col-wd-sm {
      width: 120px
  }

  .data-col-wd-md {
      width: 160px
  }

  .data-col-wd-lg {
      width: 200px
  }
}

@media (min-width: 992px) {
  .data-details-date {
      padding-bottom: 0
  }

  .data-details-docs {
      display: flex
  }

  .data-details-docs > li {
      width: 33%;
      border: none;
      border-left: 1px solid #d2dde9
  }

  .data-col .text-wrap {
      width: 190px
  }

  .data-col-wd-sm {
      width: 140px
  }

  .data-col-wd-md {
      width: 180px
  }

  .data-col-wd-lg {
      width: 220px
  }
}